// Step 1: Import React
import * as React from 'react'
import {Link} from "gatsby"
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
// Step 2: Define your component
const AboutPage = () => {
  return (
    <Layout pageTitle="About Me">
      <p>Hi there! I'm a developer and machine learning practioner. I love tinkering but also making production-ready stuff</p> 
      <StaticImage
        alt="My Avatar"
        src="../images/avataaars.png"
      />
    </Layout>
  )
}
// Step 3: Export your component
export default AboutPage